import React from "react";
import SignInLayout from "../../components/layout/SignInLayout";
import Authenticator from "../../components/auth/Authenticator";

const AboutYourTeam = ({ connectorData, partnerDetails, location: { state } }) => {
  state = { account_type: "lsp", partner_id: state?.partnerID,  partner: partnerDetails.slug}

  return (
    <SignInLayout vendorDetails={partnerDetails} connectorData={connectorData}>
      <Authenticator state={state}/>
    </SignInLayout>
  );
}

export default AboutYourTeam;
