import React, { useEffect } from "react";
import Amplify from "aws-amplify";
import { Router } from "@reach/router";
import ROUTES from "../config/routes";
import PartnerRedirect from "../containers/partner/Redirect";
import AboutYourTeam from "../containers/partner/AboutYourTeam";
import { graphql } from "gatsby"
import awsconfig from "../aws-exports"
import HybridStorage from "../config/hybridStorage"
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

Amplify.configure({
  ...awsconfig,
  storage: new HybridStorage({
    domain: process.env.NEPAL_DOMAIN,
    expires: 7,
    path: "/",
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
  }),
});

const redirectToApp = () => {
  const url = new URL(window.location.href)
  url.host = url.host.replace(/^[^.]*/, "app")
  url.pathname = "/"
  url.search = query
  return window.location.href = url.href
}

const Partner = ({ data }) => {
  useEffect(() => {
    onAuthUIStateChange((newAuthState) => {
      if (newAuthState === AuthState.SignedIn) {
        redirectToApp();
      }
    })
  }, [])

  const { allContentfulConnector: { nodes: connectorData }, allContentfulPartner: {nodes: [partnerDetails]} } = data;
  return <Router basepath="/">
    <AboutYourTeam partnerDetails={partnerDetails} connectorData={connectorData} path={ROUTES.PARTNER_ABOUT_YOUR_TEAM.path} />
    <PartnerRedirect partner="protemos" connectors path={ROUTES.PARTNER_REDIRECT.path} />
  </Router>;
}

export default Partner;

export const query = graphql`
  query {
    allContentfulPartner(filter: {slug: {eq: "protemos"}}) {
      nodes {
        name
        slug
        logo {
          file {
            url
          }
        }
      }
    }
    allContentfulConnector {
      nodes {
        logo {
          file {
            url
          }
          title
        }
        id: slug
        status
        type
        url
        name
        category
        settings {
          hasAutoUpdate
          enablePushContent
        }
      }
    }
  }
`
