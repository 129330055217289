import { API } from "aws-amplify";
import { useLocation } from "@reach/router"
import { FormattedMessage } from "gatsby-plugin-intl";
import React, { useEffect } from "react";
import Spinner from "../../components/common/Spinner";
import CenteredLayout from "../../components/layout/CenteredLayout";
import { navigate } from "@reach/router"

const PartnerRedirect = ({ partner, partnerID }) => {
  const location = useLocation()

  useEffect(() => {
    API.get("nepal", `/partner/${partner}/${partnerID}`).then((data) => {
      if (data.registered) {
        const url = new URL(window.location.href)
        url.host = url.host.replace(/^[^.]*/, data.subdomain)
        url.pathname = "/en/signin"
        url.search = location.search
        return window.location.href = url.href;
      }
      return navigate(`/protemos${location.search}`, { state: { partnerID } });
    });
  }, []);

  return (
    <CenteredLayout>
      <Spinner/>
      <h6 className="text-uppercase text-muted my-4">
        <FormattedMessage
          id="pages.partner.redirect.header"
          defaultMessage="Redirecting!"
        />
      </h6>
    </CenteredLayout>
  );
}

export default PartnerRedirect